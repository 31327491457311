import React from 'react';
import type { FC } from 'react';
import {makeStyles} from "@material-ui/core";
import {Theme} from "../theme";

interface LogoProps {
  [key: string]: any;
}

const useStyles = makeStyles((theme: Theme) => ({

  logoStyles: {
    maxWidth:"85px"
  }
}));


const Logo: FC<LogoProps> = (props) => {
    const classes = useStyles();

  return (
    <img
      alt="Logo"
      src="/static/fackl.png"
      className={classes.logoStyles}
      // src="/static/logo.jpg"
      {...props}
    />
  );
}

export default Logo;
