import moment from 'moment';
import mock from 'src/utils/mock';
import type {
  Project,
} from 'src/types/reports';


mock.onGet('/api/reports/earnings').reply(200, {
  earnings: {
    datasets: [
      {
        data: [56, 24, 20],
        backgroundColor: ['#3d72eb', '#4b9e86', '#b658f5']
      }
    ],
    labels: ['Subscriptions', 'Affiliate', 'Sales']
  }
});


mock.onGet('/api/reports/latest-projects').reply(() => {
  const projects: Project[] = [
    {
      id: '5eff24e675e7b3cba23e4be7',
      author: {
        avatar: '/static/images/avatars/avatar_5.png',
        name: 'Anje Keizer'
      },
      budget: 12500,
      createdAt: moment()
        .subtract(2, 'hours')
        .subtract(34, 'minutes')
        .subtract(10, 'seconds')
        .toDate()
        .getTime(),
      currency: '$',
      technologies: ['angular'],
      title: 'Mella Full Screen Slider'
    },
    {
      id: '5eff24e98e2c9107e95cb827',
      author: {
        avatar: '/static/images/avatars/avatar_9.png',
        name: 'Emilee Simchenko'
      },
      budget: 15750,
      createdAt: moment()
        .subtract(10, 'hours')
        .subtract(56, 'minutes')
        .subtract(25, 'seconds')
        .toDate()
        .getTime(),
      currency: '$',
      technologies: ['sketch', 'html-css'],
      title: 'Dashboard Design'
    },
    {
      id: '5eff24f0d97353e3576d3c26',
      author: {
        avatar: '/static/images/avatars/avatar_10.png',
        name: 'Elliott Stone'
      },
      budget: 15750,
      createdAt: moment()
        .subtract(1, 'days')
        .subtract(30, 'minutes')
        .subtract(50, 'seconds')
        .toDate()
        .getTime(),
      currency: '$',
      technologies: ['react-js'],
      title: 'Ten80 Web Design'
    },
    {
      id: '5eff24f737bc6b191dd9bf58',
      author: {
        avatar: '/static/images/avatars/avatar_11.png',
        name: 'Shen Zhi'
      },
      budget: 12500,
      createdAt: moment()
        .subtract(1, 'days')
        .subtract(4, 'minutes')
        .subtract(30, 'seconds')
        .toDate()
        .getTime(),
      currency: '$',
      technologies: ['vue-js'],
      title: 'Neura e-commerce UI Kit'
    },
    {
      id: '5eff24fb29fc5e37bdab3b2d',
      author: {
        avatar: '/static/images/avatars/avatar_3.png',
        name: 'Cao Yu'
      },
      budget: 15750,
      createdAt: moment()
        .subtract(1, 'days')
        .subtract(11, 'minutes')
        .subtract(6, 'seconds')
        .toDate()
        .getTime(),
      currency: '$',
      technologies: ['angular', 'figma'],
      title: 'Administrator Dashboard'
    }
  ];

  return [200, { projects }];
});
