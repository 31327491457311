import axios from 'axios';

const axiosInstance = axios.create({
  // baseURL: 'https://4t6ru67t87.execute-api.us-west-2.amazonaws.com',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
