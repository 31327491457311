import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import OnBoardGuard from 'src/components/OnBoardGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/dashboard',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/DashboardView'))
  },
  {
    exact: true,
    path: '/settings',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/SettingsView'))
  },


    // TODO: Get /login-callback & OnBoardGuard working
  {
    exact: true,
    path: '/login-callback',
    guard: OnBoardGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/DashboardView'))
  },


  {
    exact: true,
    path: '/complete-profile',
    guard: AuthGuard,
    // layout: DashboardLayout,
    component: lazy(() => import('src/views/CompleteProfileView'))
  },
   {
    exact: true,
    path: '/payment',
    guard: AuthGuard,
    component: lazy(() => import('src/views/PaymentView'))
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/login" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
