import React, {useCallback, useEffect} from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import getUserNew from '../api/reactQuery';





interface OnboardGuardProps {
  children?: ReactNode;
}

const OnBoardGuard: FC<OnboardGuardProps> = ({ children }) => {
  /**
   * OnBoardGuard hits the get_user endpoint. If a user exists in our database,
   * send them to the dashboard. If the user does not exist, send them to the
   * onboard flow.
   */
  //TODO: if user exists, check their subscription status
  //TODO: replace getUser fetch with React Query (https://react-query.tanstack.com/)


  // Auth0
  const { isAuthenticated } = useAuth();
  const { authToken } = useAuth(); // auth0 token

  // for API calls
  const serverUrl = process.env.REACT_APP_SERVER_URL; // AWS API base URL
  const [userExists, setUserExists] = React.useState(null);
  const [userIsSubscribed, setUserIsSubscribed] = React.useState(null);
  const isMountedRef = useIsMountedRef();


  // React Query
  // const {isLoading: userIsLoading, error: userError, data: userData} = useQuery(['getUser', authToken], () => getUserNew(authToken));

  // if (userIsLoading) {
  //   console.log("LOADING");
  // }
  // if (userError) {
  //   console.log("ERROR");
  // }
  // if (userData) {
  //   console.log("DATA");
  // }





  const getUser = useCallback( async () => {
    console.log("HERE1");
    console.log("HERE2");

    try {
      fetch(`${serverUrl}/user`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          method: 'GET',
        },
      ).then(response => {
        if (response.status===200) { // User exists
          setUserExists(true);
        }
        else if (response.status===404) { // User doesn't exist
          setUserExists(false);
        }
        else {
          console.log("ERROR: " + response.status);
        }
        return response.json();
      }).then(userJSON => {
        console.log("USER: " + userJSON);
        try {
          console.log("SUBSCRIPTION_STATUS: " + userJSON['subscription_status']);
          setUserIsSubscribed(userJSON.subscription_status === "active");
        } catch (e) {
          const result = (e as Error).message;
          console.log(result);
          console.log("API ERROR");
          console.error(e);
        }
        if (isMountedRef.current) {
        }
      });
    }
    catch (e) {
      console.error(e);
    }
  }, [isMountedRef]);


  useEffect(() => {
    getUser();
  }, [getUser]);




  if (isAuthenticated) {
    if (userExists === false) {
      return <Redirect to="/complete-profile"/>;
    } else if (userExists === true) {
      return <Redirect to="/dashboard"/>;
    }
  }

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }


  return (
    <>
      {userExists === null ? null : children}
    </>
  );
};


OnBoardGuard.propTypes = {
  children: PropTypes.node
};

export default OnBoardGuard;
